<template>
  <div></div>
</template>

<script>
export default {
  name: 'Notes_CreateOrUpdate',
};
</script>

<style scoped>

</style>
